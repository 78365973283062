<link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
  rel="stylesheet">
<div class="background">
  <div class="logo">
    <img src="/assets/img/bordered_warp_logo_blue.png" alt="WARP">
  </div>
  <div class="box-container">
    <div class="body">
      <div *ngIf="isLoading" loading-bar class="loading">

      </div>
      <pdf-viewer (after-load-complete)="onLoaded($event)" [src]="fileUrl" style="height: calc(100vh - 50px);" ></pdf-viewer>

    </div>
  </div>
</div>