import { Const } from "@const/Const";
import { ApiService } from "./api.service"
import { Log } from "./log";
import { LocalStorageKey } from "@const/LocalStorageKey";

export class FreightQuoteLogsService {
  constructor(private api: ApiService) {
  }

  public setLogId(id){
    localStorage.setItem(LocalStorageKey.FREIGHT_QUOTE_ID, id);
  }

  public getLogId(){
    return localStorage.getItem(LocalStorageKey.FREIGHT_QUOTE_ID);
  }
  
  public updateFreightQuoteLog(params) {
    const keyFreightQuoteId = this.getLogId();
    if (keyFreightQuoteId) {
      this.api.PUT(`${Const.APIURI_FREIGHT_QUOTE_LOGS}/${keyFreightQuoteId}`, params).subscribe(
        (resp) => {
          Log.d(resp);
        },
        (err) => {
          Log.e(err);
        }
      );
    }
  }
}