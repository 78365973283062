<div class="lp-form-title">
  Your password has expired!
</div>

<div class="lp-form-description">
  Please change your password to continue.
</div>

<form [formGroup]="formInput" nz-form>
  <ng-container *ngFor="let key of formInputKeys">
    <div class="form-label-v1">{{getLabel(key)}}</div>
    <nz-form-item class="form-value-v1">
      <ng-container [ngSwitch]="key">
        <nz-input-group [nzSuffix]="suffixTemplate">
          <input #inputCode nz-input
            [formControlName]="key" type="text" name="otp"
            [placeholder]="getPlaceHolder(key)"
            (input)="onInputChanged($event, key)"
            (keypress)="onInputKeyPress($event, key)"
            [type]="getPasswordVisible(key) ? 'text' : 'password'"
            (blur)="onBlur(key)"
            (focus)="onFocus(key)"
            [ngClass]="{'error': isShowError(key)}"
            (ngModelChange)="handleShowError(key)"
          />
        </nz-input-group>
        <ng-template #suffixTemplate>
          <i nz-icon [nzType]="getPasswordVisible(key) ? 'eye-invisible' : 'eye'" (click)="togglePasswordVisibility(key)"></i>
        </ng-template>
        <!-- error message -->
        <div class="error-message" *ngIf="isShowError(key)">
          {{getErrorMessage(key)}}
        </div>
        <!-- conditions -->
        <div *ngIf="showConditions?.[key]">
          <div *ngFor="let condition of passwordConditions" class="password-condition" [ngClass]="condition.check(formInput.get(key).value) ? 'active' : ''">
            <span nz-icon nzType="check" nzTheme="outline" ></span>
            <span>{{condition.label}}</span>
          </div>
        </div>
      </ng-container>
    </nz-form-item>
  </ng-container>
</form>

<button nz-button class="lp-button submit" [disabled]="isDisableBtnVerify()" (click)="onBtnVerify()">
  <i *ngIf="onProgress" class="ic-right" nz-icon nzType="loading" nzTheme="outline"></i>Change Password
</button>
