import { AttachedFile } from "@wearewarp/types/data-model";
import printJS from 'print-js'
import { AttachedFileUtil } from "./attached-file-util";

export class PrintService {

    public static print(model: AttachedFile) {
        let fileUrl = AttachedFileUtil.attachedFileUrl(model);
        if (AttachedFileUtil.isPdf(model)) {
            return printJS(fileUrl);
        } else {
            return printJS(fileUrl, 'image');
        }
    }

}