import to from 'await-to-js';
import { Component } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: '[password-expired]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss', '../public/style.scss', '../../styles/form-v1.scss']
})
export class PasswordExpired extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    oldPw: { label: 'Current Password:', required: true, placeHolder: 'Current password' },
    newPw: { label: 'New Password:', required: true, placeHolder: 'New password' },
    confirmPw: { label: 'Confirm Password:', required: true, placeHolder: 'Confirm password' }
  };

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  get canVerify() {
    return !this.onProgress && this.needUpdate;
  }

  ngOnInit() {
    if (!this.authUser) window.location.href = '/login';
    super.ngOnInit();
  }

  private passwordVisible = {
    oldPw: false,
    newPw: false,
    confirmPw: false,
  };

  showConditions = {
    newPw: false
  }

  passwordConditions = [
    { label: "8~20 characters", check: (val) => this.isValidLength(val) },
    { label: "Include uppercase", check: (val) => this.haveUppercase(val) },
    { label: "Include lowercase", check: (val) => this.haveLowercase(val) },
    { label: "Include number", check: (val) => this.haveNumber(val) },
  ];

  private errorMessages = {
    CURRENT_PASSWORD_NOT_MATCHED: 'Password is incorrect',
    PASSWORD_CONFIRM_NOT_MATCHED: 'Password does not match',
    NEW_PASSWORD_SAME_AS_OLD_PASSWORD: 'New password must be different from old password'
  };

  private err = {
    oldPw: {
      message: this.errorMessages.CURRENT_PASSWORD_NOT_MATCHED,
      isShow: false
    },
    confirmPw: {
      message: this.errorMessages.PASSWORD_CONFIRM_NOT_MATCHED,
      isShow: false
    },
    newPw: {
      message: this.errorMessages.NEW_PASSWORD_SAME_AS_OLD_PASSWORD,
      isShow: false
    }
  }

  onFocus(key: string) {
    if (key === 'newPw') {
      this.showConditions[key] = true;
    }
  }

  onBlur(key: string) {
    if (key === 'newPw') {
      this.handleShowCoditions(key);
    }
  }

  private handleShowCoditions(key: string) {
    const newPw = this.getFormData_JSON(true).newPw;
    this.showConditions[key] = !this.validatePassword(newPw);
  }

  private validatePassword(val: string): boolean {
    return this.haveLowercase(val) && this.haveUppercase(val) && this.haveNumber(val) && this.isValidLength(val);
  }

  private haveLowercase(val: string): boolean {
    if (!val) {
      return false;
    }
    return /[a-z]/.test(val);
  }

  private haveUppercase(val: string): boolean {
    return /[A-Z]/.test(val);
  }

  private haveNumber(val: string): boolean {
    return /[0-9]/.test(val);
  }

  private isValidLength(val: string): boolean {
    return val?.length >= 8 && val?.length <= 20;
  }

  getPasswordVisible(key: string): boolean {
    return this.passwordVisible[key];
  }

  togglePasswordVisibility(key: string) {
    this.passwordVisible[key] = !this.passwordVisible[key];
  }

  handleShowError(key: string) {
    const formData = this.getFormData_JSON(true);

    if (key === 'oldPw') {
      this.err.oldPw.isShow = false;
    }

    if (this.validatePassword(formData.newPw)) {
      this.err.newPw.isShow = formData.oldPw?.length && formData.oldPw === formData.newPw;
      this.err.confirmPw.isShow = formData.confirmPw?.length && formData.confirmPw !== formData.newPw;
    }
  }

  isShowError(key: string): boolean {
    return this.err[key].isShow;
  }

  getErrorMessage(key) {
    return this.err[key].message;
  }

  private get isPassValidate() {
    return this.canVerify && !this.err.oldPw.isShow && !this.err.confirmPw.isShow && !this.err.newPw.isShow && this.validatePassword(this.getFormData_JSON(true).newPw);
  }

  isDisableBtnVerify() {
    return !this.isPassValidate
  }

  async onBtnVerify() {
    this.startProgress();
    const data: any = this.getFormData_JSON(true);
    const [err] = await to(this.auth.changePw({
      old_pw: data.oldPw,
      new_pw: data.newPw,
    }).toPromise());

    if (err) {
      if (err?.message === 'Old password does not match') {
        this.err.oldPw.isShow = true;
      } else {
        this.showErr(err);
      }
      this.stopProgress();
      return;
    }
    this.showSuccess('Password changed successfully. Please login again.');
    this.auth.logout();
    window.location.href = '/login';
  }

}
