import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from "./api.service";
import { environment } from "@env/environment";
import { Const } from '@const/Const';

const shipmentTypeAliases = {
    "PALLET": "LTL"
}

export class PricingService {
    api: ApiService = null
    constructor(api: ApiService) {
        this.api = api
    }


    getShipmentType(shipmentType: string): string {
        if (!shipmentType) return shipmentType;
        const normalized: string = shipmentType.toUpperCase();
        return shipmentTypeAliases[normalized] || normalized;
    }
    private processItem(packagingType, height, width, length, parcelSizeSelected, weightPerUnit, weightUnit, qty) {
        return {
                qtyUnit: packagingType,
                dimensions: {
                    height: {
                        value: height,
                        unit: 'in'
                    },
                    width: {
                        value: width,
                        unit: 'in'
                    },
                    length: {
                        value: length,
                        unit: 'in'
                    },
                    size: parcelSizeSelected
                },
                weight: {
                    value: weightPerUnit,
                    unit: weightUnit || 'lbs'
                },
                qty
        }
    }

    public createPrice(request: PricingRequest, options: {useDummy?: boolean, noError?: boolean} = {}) {
        if (options.useDummy) {
            return of(dummyResponse());
        }
        const url = this.api.buildUrl(`${environment.ratingUrl}/price`)
        const { height, length, width, weightPerUnit, weightUnit, packagingType, qty, parcelSizeSelected, ltlItems } = request.packaging || {}
        const pickupServiceOptions = request.pickupServices ? request.pickupServices.map(a => a._id) : []
        const dropoffServiceOptions = request.dropoffServices ? request.dropoffServices.map(a => a._id) : [];
        const listItems = [];
        if (packagingType == Const.packagingTypeList[1] && ltlItems?.length) {
            ltlItems.forEach(item => {
                listItems.push(this.processItem(packagingType, item.height, item.width, item.length, parcelSizeSelected, item.weightPerUnit, item.weightUnit, item.qty));
            });
        } else {
            listItems.push(this.processItem(packagingType, height, width, length, parcelSizeSelected, weightPerUnit, weightUnit, qty));
        }
        const payload = {
            shipmentType: this.getShipmentType(request.shipmentType),
            shipmentMode: request.shipmentMode,
            deliveryInfos: [
                {
                    type: 'PICKUP',
                    addr: {
                        zipcode: request.originZipcode
                    },
                    serviceOptions: pickupServiceOptions,
                },
                {
                    type: 'DROPOFF',
                    addr: {
                        zipcode: request.destinationZipcode
                    },
                    serviceOptions: dropoffServiceOptions,
                }
            ],
            listItems,
        }
        if (options.noError) {
            return this.api.POST(url, payload).pipe(
                catchError(e => of(null))
            );
        }
        return this.api.POST(url, payload);
    }

    public price = (request: PricingRequest, success, error) => {
        return this.createPrice(request).subscribe(
            resp => {
                if (resp?.offers?.length && resp?.offers?.length > 0)
                    success && success(resp)
                else
                    error && error({
                        message: 'No offer available!'
                    })
            }, err => {
                error && error(err)
            }
        )
    }

    public get = (id, success, error) => {
        const url = this.api.buildUrl(`${environment.ratingUrl}/price/${id}`)
        return this.api.GET(url).subscribe(
            resp => {
                success && success(resp)
            }, err => {
                error && error(err)
            }
        )
    }
}

function dummyResponse() {
    return {
        "metadata": {
            "eta_distance": "583544.25",
            "eta_time": "23698"
        },
        "id": "3886cff7-1485-48fa-bfa1-825f90c792c5",
        "createdTs": "2022-07-28T18:05:38.282Z",
        "updatedTs": "2022-07-28T18:05:38.282Z",
        "clientId": 2,
        "shipmentType": "FTL",
        "shipmentMode": "",
        "listItems": [
            {}
        ],
        "deliveryInfos": [
            {
                "type": "PICKUP",
                "addr": {
                    "metadata": {
                        "latitude": "37.7453986",
                        "longitude": "-122.1031272"
                    },
                    "zipcode": "94546"
                }
            },
            {
                "type": "DROPOFF",
                "addr": {
                    "metadata": {
                        "latitude": "34.0659747",
                        "longitude": "-118.2386422"
                    },
                    "zipcode": "90012"
                }
            }
        ],
        "offers": [
            {
                "id": "5862e13f-20ae-444f-82b7-614f40f14e89",
                "orders": [
                    {
                        "metadata": {
                            "day": "1",
                            "eta_time": "23698",
                            "eta_distance": "583544.25"
                        },
                        "clientId": 2,
                        "listItems": [
                            {}
                        ],
                        "shipmentType": "FTL"
                    }
                ],
                "quotes": [
                    {
                        "createdTs": "2022-07-28T18:05:38.262Z",
                        "object": {
                            "metadata": {
                                "day": "1",
                                "eta_time": "23698",
                                "eta_distance": "583544.25"
                            },
                            "clientId": 2,
                            "listItems": [
                                {}
                            ],
                            "shipmentType": "FTL"
                        },
                        "formula": {
                            "name": "FLAT RATE",
                            "key": "flat-rate",
                            "parsed": {
                                "type": "PARAM",
                                "name": "tl",
                                "value": {
                                    "type": "DOUBLE",
                                    "value": 2000000.0
                                }
                            },
                            "items": [
                                {
                                    "name": "Truck Load",
                                    "key": "tl",
                                    "parsed": {
                                        "type": "MUL",
                                        "left": {
                                            "type": "PARAM",
                                            "name": "tl_rate",
                                            "value": {
                                                "type": "DOUBLE",
                                                "value": 20000.0
                                            }
                                        },
                                        "right": {
                                            "type": "CONST",
                                            "value": {
                                                "type": "INT",
                                                "value": 100
                                            }
                                        },
                                        "value": {
                                            "type": "DOUBLE",
                                            "value": 2000000.0
                                        }
                                    }
                                }
                            ]
                        },
                        "value": {
                            "type": "DOUBLE",
                            "value": 2000000.0
                        }
                    }
                ],
                "plusDays": 1,
                "totalPrice": 2000000.0
            },
            {
                "id": "a6257522-290a-42fb-8947-1dc54979afa4",
                "orders": [
                    {
                        "metadata": {
                            "day": "7",
                            "eta_time": "23698",
                            "eta_distance": "583544.25"
                        },
                        "clientId": 2,
                        "listItems": [
                            {}
                        ],
                        "shipmentType": "FTL"
                    }
                ],
                "quotes": [
                    {
                        "createdTs": "2022-07-28T18:05:38.264Z",
                        "object": {
                            "metadata": {
                                "day": "7",
                                "eta_time": "23698",
                                "eta_distance": "583544.25"
                            },
                            "clientId": 2,
                            "listItems": [
                                {}
                            ],
                            "shipmentType": "FTL"
                        },
                        "formula": {
                            "name": "FLAT RATE",
                            "key": "flat-rate",
                            "parsed": {
                                "type": "PARAM",
                                "name": "tl",
                                "value": {
                                    "type": "DOUBLE",
                                    "value": 2000000.0
                                }
                            },
                            "items": [
                                {
                                    "name": "Truck Load",
                                    "key": "tl",
                                    "parsed": {
                                        "type": "MUL",
                                        "left": {
                                            "type": "PARAM",
                                            "name": "tl_rate",
                                            "value": {
                                                "type": "DOUBLE",
                                                "value": 20000.0
                                            }
                                        },
                                        "right": {
                                            "type": "CONST",
                                            "value": {
                                                "type": "INT",
                                                "value": 100
                                            }
                                        },
                                        "value": {
                                            "type": "DOUBLE",
                                            "value": 2000000.0
                                        }
                                    }
                                }
                            ]
                        },
                        "value": {
                            "type": "DOUBLE",
                            "value": 2000000.0
                        }
                    }
                ],
                "plusDays": 7,
                "totalPrice": 2000000.0
            },
            {
                "id": "0a0fad74-b589-4722-8beb-50704b3fa5af",
                "orders": [
                    {
                        "metadata": {
                            "day": "14",
                            "eta_time": "23698",
                            "eta_distance": "583544.25"
                        },
                        "clientId": 2,
                        "listItems": [
                            {}
                        ],
                        "shipmentType": "FTL"
                    }
                ],
                "quotes": [
                    {
                        "createdTs": "2022-07-28T18:05:38.282Z",
                        "object": {
                            "metadata": {
                                "day": "14",
                                "eta_time": "23698",
                                "eta_distance": "583544.25"
                            },
                            "clientId": 2,
                            "listItems": [
                                {}
                            ],
                            "shipmentType": "FTL"
                        },
                        "formula": {
                            "name": "FLAT RATE",
                            "key": "flat-rate",
                            "parsed": {
                                "type": "PARAM",
                                "name": "tl",
                                "value": {
                                    "type": "DOUBLE",
                                    "value": 2000000.0
                                }
                            },
                            "items": [
                                {
                                    "name": "Truck Load",
                                    "key": "tl",
                                    "parsed": {
                                        "type": "MUL",
                                        "left": {
                                            "type": "PARAM",
                                            "name": "tl_rate",
                                            "value": {
                                                "type": "DOUBLE",
                                                "value": 20000.0
                                            }
                                        },
                                        "right": {
                                            "type": "CONST",
                                            "value": {
                                                "type": "INT",
                                                "value": 100
                                            }
                                        },
                                        "value": {
                                            "type": "DOUBLE",
                                            "value": 2000000.0
                                        }
                                    }
                                }
                            ]
                        },
                        "value": {
                            "type": "DOUBLE",
                            "value": 2000000.0
                        }
                    }
                ],
                "plusDays": 14,
                "totalPrice": 2000000.0
            }
        ]
    };
}