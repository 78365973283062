<link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
  rel="stylesheet">
<div class="background">
  <div class="box-container">
    <div class="logo">
      <img src="/assets/img/bordered_warp_logo_blue.png" alt="WARP">
    </div>
    <div class="body">
      <div class="search-box">
        <nz-input-group class="date">
          <nz-date-picker [(ngModel)]="date" nzSize="large" style="width: 100%;" nzPlaceHolder="Load Tender Date"></nz-date-picker>
        </nz-input-group>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" nzSize="large" >
          <input type="text" nz-input placeholder="Search The WARP's Load Tender" [(ngModel)]="searchInput" (keyup)="onSearchPress($event)" />
        </nz-input-group>
        <ng-template #suffixIconButton>
          <button nz-button nzType="primary" nzSearch [nzLoading]="isLoading" (click)="onSearch()"><span nz-icon nzType="search"></span></button>
        </ng-template>
      </div>

      <div class="description">
        This page is intended to confirm <b>WARP's Load Tender</b> information. <br/>
        You can scan the QR code on the load tender, or enter the code you found on the load tender.
      </div>

    </div>
  </div>
</div>