import { Const } from "@const/Const";
import { ApiService } from "./api.service";

import { map } from "rxjs/operators";
import { Observable, Subject } from "rxjs";

class FeatureFlagService {
    api: ApiService = null
    baseUrl: string = null
    private user: any = null
    cache: any = null
    calling: Subject<any> = null

    constructor(api?: ApiService ) {
        this.api = api
        this.baseUrl = Const.APIV2(`metadata`)
    }

    setUser(user) {
        if (user?.id === this.user?.id) return
        this.user = user
        this.cache = null

        // pre-pull
        if (!user) return
        const url = `${this.baseUrl}/USER_${this.user.id}/FEATURE_FLAG`
        this.calling = new Subject()
        this.api.GET(url).subscribe( (data) => {
            if (data) {
                this.cache = data || {}
                this.calling.next(data)
            }
        }, (err) => {
            this.cache = {}
            this.calling.next({})
        })
    }

    isFlagSet(name: string): boolean {
        if (!this.user)
            return false
        if (this.cache && this.cache[name]) {
            return this.cache[name] === 'true'
        }
        
        return false
    }

    isFlagSetNonBlocking(name: string): Observable<boolean> {
        if (!this.user)
            return new Observable((observer) => observer.next(false))
        if (this.cache && this.cache[name]) {
            return new Observable((observer) => observer.next(this.cache[name] === 'true'))
        }

        return this.calling
        .pipe(
            map(data => {
                return data?.[name] === 'true';
            })
        )
    }
}

const featureFlag = new FeatureFlagService()

export const getFeatureFlags = (api?: ApiService, user?) => {
    if (api)
        featureFlag.api = api
    if (user)
        featureFlag.setUser(user)
    return featureFlag
}