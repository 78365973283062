import { Const } from "@const/Const";
import { DeliveryInfo } from "@wearewarp/types/data-model";
import { MasterData } from "./master.data";
import { FormDataShipmentEntryDraftBatch } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";

export class OrderUtil {
  public static getTimeWindowForDeliveryInfo(info: DeliveryInfo) {
    let window = info.windows?.[0];
    if (!window && info.requiresAppointment) {
      window = info.appointmentInfo;
    }
    return window;
  }

  public static getShipmentTypeName(code: string): string {
    switch (code) {
      case Const.ShipmentTypes.fullTruckLoad: return 'Full Truckload';
      case Const.ShipmentTypes.lessThanTruckload: return 'Less Than Truckload';
      case Const.ShipmentTypes.parcel: return 'Parcel';
    }
    return '';
  }
}