import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { PageError } from './page-error';
import { ComingSoon } from './coming-soon';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
    ],
    declarations: [PageError, ComingSoon],
    exports: [PageError, ComingSoon]
})

export class PageErrorModule {}
