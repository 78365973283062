import { ApiService } from "./api.service";

import { environment } from "@env/environment";

export class ZipcodeService {
    api: ApiService = null
    baseUrl: string = null
    
    constructor(api: ApiService) {
        this.api = api
        this.baseUrl = environment.zipcodeUrl
    }

    public get = (zipcode, success, error) => {
        const url = this.api.buildUrl(`${this.baseUrl}/${zipcode}`)
        return this.api.GET(url).subscribe(
            resp => {
                if (resp.data)
                    success && success(resp.data)
                else
                    error && error({'message': 'Not found'})
            }, err => {
                error && error(err)
            }
        )
    }

    public getTimeZoneByZipcode = (zipcode, success, error) => {
      const url = this.api.buildUrl(`${this.baseUrl}/${zipcode}/timezone`)
      return this.api.GET(url).subscribe(
          resp => {
              if (resp.data)
                  success && success(resp.data)
              else
                  error && error({'message': 'Not found'})
          }, err => {
              error && error(err)
          }
      )
  }
}