import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

export interface ComponentCheckCanQuit {
  canQuit: () => string;
}

@Injectable()
export class CheckCanQuit implements CanDeactivate<ComponentCheckCanQuit> {
  canDeactivate(component: ComponentCheckCanQuit, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    let message = component?.canQuit();
    if (message) {
      if (confirm(message)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}