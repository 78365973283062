<div class="searchbox">
  <nz-input-group [nzPrefix]="prefixIconSearch" [nzSuffix]="suffixIconClear" nzSize="default">
    <input #inputField nz-input type="text" [(ngModel)]="searchKeyword" [placeholder]="placeHolder" (keyup.enter)="onSearchEnter()" (focusout)="onInputFieldFocusOut()"/>
  </nz-input-group>
  <ng-template #prefixIconSearch>
    <i nz-icon nzType="search" (click)="onSearchEnter()" style="cursor: pointer;"></i>
  </ng-template>
  <ng-template #suffixIconClear>
    <i nz-icon nz-tooltip class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" *ngIf="searchKeyword && searchKeyword.length>0" (click)="clearSearchKeyword()"></i>
  </ng-template>
</div> 