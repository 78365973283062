import { AppConst } from '@app/const.generated';
import { Const } from '@const/Const';
import { LatLng } from '@wearewarp/types/data-model';
import { ulid } from 'ulid';

const VALUE_2_POW_32 = Math.pow(2, 32);

export class Utils {
  public static isMobile(): boolean {
    return window.innerWidth <= Const.MOBILE_WIDTH;
  }

  public static isAlphaNumeric(str) {
    return str && str.match(/^[a-zA-Z0-9]+$/) != null;
  }

  public static onlyNumbers(str) {
    if (typeof str != 'string') {
      return str;
    }
    return str.replace(/[^0-9]/g, '');
  }

  // Split name to first name and last name
  public static splitName(name = '') {
    if (!name) return { firstName: "", lastName: "" };
    let nameWords = name.split(" ");
    let firstName = nameWords[0];
    let lastName = "";
    for(let i in nameWords) {
      let item = nameWords[i]
      if (i !== "0") lastName += `${item} `;
    }
    return { firstName, lastName: lastName.trim() };
  }

  public static capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public static capitalize(str) {
    if (!str) return str
    return str.replace('_', ' ').split(' ').map(it => it.charAt(0).toUpperCase() + it.slice(1).toLowerCase()).join(' ')
  }

  // A replacement for Math.random that uses cryptographically secure random number generation
  // https://github.com/michaelrhodes/math-random
  // lessSecure true: để dùng khi cần nhanh, ko cần bảo mật -> sẽ dùng Math.random()
  public static random(lessSecure: boolean = false) {
    if (lessSecure) return Math.random();
    let uint32 = 'Uint32Array' in window;
    let crypto = window.crypto || (<any>window).msCrypto;
    let rando = crypto && typeof crypto.getRandomValues === 'function';
    let good = uint32 && rando;
    if (!good) return Math.random();
    let arr = new Uint32Array(1)
    crypto.getRandomValues(arr);
    return arr[0] / VALUE_2_POW_32;
  }

  public static getErrorString(err) {
    if (!err) {
      return '';
    }
    if (typeof err === 'string') {
      return err;
    }
    if (err.constructor.name === 'Response') {
      err = err.json();
    }
    if (err.ui_message) {
      return err.ui_message;
    } else if (err.message) {
      return err.message;
    } else {
      try {
        return JSON.stringify(err);
      } catch (e) {
        return err;
      }
    }
  }

  public static getSuccessString(resp) {
    return this.getErrorString(resp);
  }

  public static defaultAvatarUrl() {
    return window.location.origin + '/assets/img/avatar_default_white.png';
  }

  public static appendQueryStringIntoUrl(url: string, query: object) {
    let paramsStr = '';
    for (let key of Object.keys(query)) {
      if (paramsStr.length > 0) {
        paramsStr += '&';
      }
      paramsStr += `${key}=${encodeURIComponent(query[key])}`;
    }
    return url.includes('?') ? `${url}&${paramsStr}` : `${url}?${paramsStr}`;
  }

  // url: http://steve.com?name=Steve&email=steve@gmail.com
  public static parseQueryStringFromUrl(url) {
    let isString = typeof url === 'string' || url instanceof String;
    if (!isString) {
      return {};
    }
    let arr = url.split('?');
    if (arr.length < 2) {
      return {};
    }
    return this.parseQueryString(arr[1]);
  }

  // query: name=Steve&email=steve@gmail.com
  public static parseQueryString(query: string) {
    return JSON.parse('{"' + query.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) });
  }

  public static downloadFile(url, fileName = null) {
    let link = document.createElement('a');
    if (fileName) {
      link.download = fileName;
    }
    link.href = url;
    link.target = "_blank";
    link.click();
  }

  public static escapeRegExp(strToEscape) {
    // Escape special characters for use in a regular expression
    return strToEscape.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
  }

  public static removeArrayItem(arr: Array<any>, index: number) {
    arr.splice(index, 1);
  }

  // compareFn returns true means a & b are the same
  public static isArraysTheSame(arr1, arr2, compareFn?: (a, b) => boolean) {
    let len1 = arr1 ? arr1.length : 0;
    let len2 = arr2 ? arr2.length : 0;
    if (!arr1 && !arr2) {
      return true;
    }
    if (!arr1 && arr2 && len2 > 0) {
      return false;
    }
    if (!arr2 && arr1 && len1 > 0) {
      return false;
    }
    if (len1 != len2) {
      return false;
    }
    for (let i = 0; i < len1; i++) {
      let equal = compareFn ? compareFn(arr1[i], arr2[i]) : arr1[i] == arr2[i];
      if (!equal) return false;
    }
    return true;
  }

  // neverNull: to make sure return object will not be null or undefined
  public static cloneObject(obj, neverNull = false) {
    if (obj === null || obj === undefined) return neverNull ? {} : obj;
    return JSON.parse(JSON.stringify(obj));
  }

  // true: obj1 và obj2 are giống nhau, false: khác nhau
  // cùng null, cùng undefined: giống nhau
  // 1 trong 2 thằng ko phải object: khác nhau
  // ko áp dụng cho nested object
  // onlyFields: chỉ so sánh cho những trường này thôi, các trường khác thì bỏ qua
  public static compareObject(obj1, obj2, onlyFields: Array<string> = null): boolean {
    if (obj1 === null && obj2 === null) return true;
    if (obj1 === undefined && obj2 === undefined) return true;
    if (!this.isObject(obj1) || !this.isObject(obj2)) return false;
    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);
    if (onlyFields && onlyFields.length > 0) {
      keys1 = keys1.filter(it => onlyFields.indexOf(it) >= 0);
      keys2 = keys2.filter(it => onlyFields.indexOf(it) >= 0);
    }
    if (keys1.length != keys2.length) return false;
    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) return false;
    }
    return true;
  }

  // Check if all properties of an object are null/undefined
  public static isAllPropertiesNullOrUndefined(obj: object): boolean {
    let keys = Object.keys(obj);
    for (let key of keys) {
      if (obj[key] && typeof obj[key] == 'object') {
        let b = this.isAllPropertiesNullOrUndefined(obj[key]);
        if (!b) {
          return false;
        }
        continue;
      }
      if (obj[key] !== null && obj[key] !== undefined) {
        return false;
      }
    }
    return true;
  }

  // remove duplicate elements in array
  public static uniqElementsArray<T>(arr: T[]) {
    return Array.from(new Set(arr));
  }

  public static generateULID(): string {
    return ulid();
  }

  /**
   * Hiển thị số đếm cho tiếng Anh, có phân biệt số ít, số nhiều
   * VD 1 shipment, 2 shipments
   * Chú ý: hàm này chỉ đúng cho tiếng Anh.
   * @param num số lượng
   * @param unitSingular danh từ đếm cho số ít
   * @param unitPlural danh từ đếm cho số nhiều (nếu không có thì tự động thêm s vào danh từ số ít)
   */
  public static displayCount(num: number, unitSingular: string, unitPlural?: string): string {
    if (num > 1) {
      if (unitPlural) {
        return `${num} ${unitPlural}`;
      } else {
        // tự động thêm s cho số nhiều
        return `${num} ${unitSingular}s`;
      }
    } else {
      return `${num} ${unitSingular}`;
    }
  }

  public static isString(variable) {
    return typeof variable === 'string' || variable instanceof String;
  }

  // string that includes all white spaces is considered as empty
  public static isStringNotEmpty(variable) {
    return this.isString(variable) && variable.trim().length > 0;
  }

  public static isNumber(variable) {
    return typeof variable === 'number' || variable instanceof Number;
  }

  public static isBoolean(variable) {
    return typeof variable === 'boolean' || variable instanceof Boolean;
  }

  public static isArray(variable) {
    return Array.isArray(variable);
  }

  public static isArrayNotEmpty(variable) {
    return Array.isArray(variable) && variable.length > 0;
  }

  public static isFunction(variable) {
    return typeof variable === 'function' || variable instanceof Function;
  }

  public static isObject(variable) {
    // https://stackoverflow.com/questions/18808226/why-is-typeof-null-object
    // have to check null because typeof null === 'object'
    return variable !== null && typeof variable === 'object';
  }

  public static isObjectNotEmpty(obj) {
    return this.isObject(obj) && Object.keys(obj).length > 0;
  }

  public static isNull(variable) {
    return variable === null;
  }

  public static isUndefined(variable) {
    return variable === undefined;
  }

  public static isUrl(str: string) {
    return this.isString(str) && (str.startsWith('http://') || str.startsWith('https://'));
  }

  public static isAssets(str: string) {
    return this.isString(str) && str.startsWith('assets/');
  }

  public static isLocalImage(str: string) {
    return str.startsWith('data:image');
  }

  public static isTouchDevice() {
    return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || ((<any>navigator).msMaxTouchPoints > 0));
  }

  public static toNumber(variable, defaultValue: number): number {
    if (variable === null || variable === undefined) return defaultValue;
    if (isNaN(variable)) return defaultValue;
    if (this.isNumber(variable)) return variable;
    let n = Number(variable);
    return isNaN(n) ? defaultValue : n;
  }

  public static objectHasProperty(obj, pro) {
    return Object.prototype.hasOwnProperty.call(obj, pro);
  }

  public static isSameLocation(l1: LatLng, l2: LatLng): boolean {
    if(!l1 || !l2) return false;
    return l1?.latitude == l2?.latitude && l1?.longitude == l2?.longitude;
  }

  public static isSameAddress(addr1, addr2) {
    if (!addr1 || !addr2) {
      return false;
    }

    if (this.isSameLocation(addr1?.metadata, addr2?.metadata)) return true;

    if (!addr1.street2) addr1.street2 = '';
    if (!addr2.street2) addr2.street2 = '';

    if (addr1.city === addr2.city &&
      addr1.state === addr2.state &&
      addr1.zipcode === addr2.zipcode &&
      addr1.street === addr2.street &&
      addr1.street2 == addr2.street2) {
      return true
    }
    return false;
  }

  public static isLocalhost() {
    return window.location.hostname == 'localhost';
  }

  // https://stackoverflow.com/questions/4907843/open-a-url-in-a-new-tab-and-not-a-new-window/28374344#28374344
  public static openInNewTab(href: string) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: href,
    }).click();
  }

  public static copyTextToClipboard(text, callback) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text, callback);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      callback(null);
    }, function (err) {
      callback(err);
    });
  }

  public static fallbackCopyTextToClipboard(text, callback) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      var successful = document.execCommand('copy');
      callback(successful ? null : "document.execCommand('copy') failed.");
    } catch (err) {
      callback(err);
    }
    document.body.removeChild(textArea);
  }

  public static selectAll(elm: HTMLElement) {
    if ((<any>document).selection) { // IE
      try {
        let range = (<any>document.body).createTextRange();
        range.moveToElementText(elm);
        range.select();
      } catch (e) { }
    } else {
      let range = document.createRange();
      range.selectNode(elm);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }
  }

  public static getResponsiveSize(width: number): ResponsiveSize {
    if (width < 576) {
      return 'xs';
    } else if (width >= 576 && width < 768) {
      return 'sm';
    } else if (width >= 768 && width < 992) {
      return 'md';
    } else if (width >= 992 && width < 1200) {
      return 'lg';
    } else if (width >= 1200 && width < 1600) {
      return 'xl';
    } else {
      return 'xxl';
    }
  }

  public static groupBy(arr: any[], key: string) {
    const initialValue = {};
    return arr.reduce((acc, cval) => {
      const myAttribute = cval[key];
      acc[myAttribute] = [...(acc[myAttribute] || []), cval]
      return acc;
    }, initialValue);
  }

  public static roundNumber(number: number, decimalPlaces: number): number {
    const factor = 10 ** decimalPlaces;
    return Math.round(number * factor) / factor;
  }

  public static maskEmail(str = null) {
    if(!str) return '';
    str = str.split('');
    let finalArr = [];
    let len = str.indexOf('@');
    str.forEach((item, pos) => {
      if(pos >= 1 && pos <= len - 2) {
        finalArr.push('*');
      }
      else finalArr.push(str[pos]);
    })
    return finalArr.join('');
  }
}
