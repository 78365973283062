import { environment } from '@env/environment';
import { EnvType } from '@env/type';

let _disableLog = environment.type != EnvType.dev;
if (localStorage.getItem("ENABLE_LOGGER") == "true") {
  _disableLog = false; //sử dụng khi cần thiết debug trực tiếp trên prod
}

export const disableLog = _disableLog;

let defineFn = (consoleFn) => {
  return {
    get: function() {
      if (disableLog) {
        return function () {};
      }
      let prefix = this.prefix;
      if (prefix.length) {
        return consoleFn.bind(window.console, prefix);
      } else {
        return consoleFn.bind(window.console);
      }
    },
    enumerable: true,
    configurable: true
  }
}

const Logger = /** @class */ (function () {
  function Logger(prefix) {
    this.disable = disableLog;
    this.prefix = '';
    if (prefix) {
      this.prefix = '[' + prefix + ']: ';
    }
  }
  Object.defineProperty(Logger.prototype, 'd', defineFn(console.log));
  Object.defineProperty(Logger.prototype, 'i', defineFn(console.info));
  Object.defineProperty(Logger.prototype, 'w', defineFn(console.warn));
  Object.defineProperty(Logger.prototype, 'e', defineFn(console.error));
  return Logger;
}());

export let Log = new Logger('WARP');
