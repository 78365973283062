import { Router } from "@angular/router";
import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { ApiService } from "@services/api.service";
import { RoleManager } from "@services/role-manager";
import { getInjector } from "./injector";
import { NzModalService } from "ng-zorro-antd/modal";
import { PermissionCode } from "@const/PermissionCode";
import { PermissionManager } from "./permission-manager";

function showDialog(message: string, onOK = () => { }) {
  let injector = getInjector();
  let modalService = injector.get(NzModalService);
  modalService.create({
    nzContent: message,
    nzClosable: false,
    nzMaskClosable: false,
    nzCentered: true,
    nzOkText: 'OK',
    nzOnOk: onOK,
    nzCancelText: null
  });
}

const mapRoleToPortal = {
  [PermissionCode.login.client]: {name: 'Customer portal', url: environment.customerWebUrl},
  [PermissionCode.login.carrier]: {name: 'Carrier portal', url: environment.carrierWebUrl},
  [PermissionCode.login.warehouse_admin]: {name: 'Warehouse portal', url: environment.warehouseWebUrl},
}

export function isSysAdmin(authUser): boolean {
  return RoleManager.userHasRole(authUser, RoleManager.systemAdmin);
}

export function isAdmin(authUser): boolean {
  return RoleManager.userHasOneOfRoles(authUser, [RoleManager.admin, RoleManager.adminReadOnly]);
}

export function isClient(authUser): boolean {
  return RoleManager.userHasRole(authUser, RoleManager.client);
}

export function isCarrier(authUser): boolean {
  return RoleManager.userHasRole(authUser, RoleManager.carrier);
}

export function isAccounting(authUser): boolean {
  return RoleManager.userHasRole(authUser, RoleManager.accounting);
}

export function isAdminReadOnlyRole(authUser): boolean {
  return RoleManager.isAdminReadOnlyRole(authUser);
}

export function isFinanceAllowed(authUser): boolean {
  return RoleManager.userHasOneOfRoles(authUser, [RoleManager.accounting, RoleManager.sale]);
}

export function verifyAfterLogin(authUser, router: Router): void {
  if (PermissionManager.allowLogin(authUser)) {
    router.navigate([Const.routeDashboard]);
    return;
  }
  let scopes = (authUser.scopes ?? []);
  let portals = [];
  for (let code of scopes) {
    if (mapRoleToPortal[code]) {
      portals.push(mapRoleToPortal[code]);
    }
  }
  let msg = 'You are not allowed to log in to admin dashboard.';
  if (portals.length == 1) {
    msg += `<br>Please try <a href="${portals[0].url}">${portals[0].name}</a>`;
  } else if (portals.length > 1) {
    msg += `<br>Please try one of these portals:`;
    for (let p of portals) {
      msg += `<br><a href="${p.url}">${p.name}</a>`
    }
  }
  ApiService.instance.logout();
  showDialog(msg);
}

export function verifyPasswordExpired(authUser, router: Router) {
  if(router.url === '/password-expired') return;
  if (authUser?.passwordExpiryDate && new Date(authUser.passwordExpiryDate) < new Date()){
    window.location.href = `/password-expired`;
    return false
  }
  return true;
}

// Vì có trường hợp customer/carrier đã login vào admin dashboard từ trước rồi nên cần gọi hàm này để logout
export function verifyWhileUsingDashboard(authUser, router: Router): void {
  if (!PermissionManager.allowLogin(authUser)) {
    ApiService.instance.logout();
    router.navigate([Const.routeLogin]);
  }
}

export function requirePermissions(authUser, permissions): boolean {
  return PermissionManager.userHasOneOfPermissions(authUser, permissions);
}