import { Component } from '@angular/core';

@Component({
  selector: 'coming-soon',
  template: `
  <div style="width:800px; margin:0 auto; text-align: center; font-size: 2em; padding-top: 50px;">
  Coming soon
  <div>
  `,
})
export class ComingSoon {

  constructor() {
  }

}