import to from "await-to-js";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { InputHelper } from "@services/input-helper";
import { DialogService } from "@dialogs/dialog.service";
import { verifyAfterLogin, verifyPasswordExpired } from "@services/auth.check-role";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";

@Component({
  selector: '[login-v1]',
  templateUrl: './comp.html',
  styleUrls: ['./style.scss', '../public/style.scss', '../../styles/form-v1.scss']
})
export class LoginV1 extends BaseFormItem {

  protected formGroupDeclaration: FormGroupDeclaration = {
    email: { label: 'Email:', required: true, placeHolder: 'Email' },
    pw: { label: 'Password:', required: true, placeHolder: 'Password' }
  };
  public passwordVisible = false;
  get canLogin() { return !this.onProgress && this.needUpdate }
  get isLoginGgInProgress(): boolean { return this.loginGgAuthUrlFetching || this.loginGgAuthCodeVerifying }
  private loginGgAuthUrlFetching = false;
  private loginGgAuthCodeVerifying = false;

  @ViewChild('inputEmail') inputEmail: ElementRef<HTMLInputElement>;
  @ViewChild('inputPw') inputPw: ElementRef<HTMLInputElement>;

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.isLoggedIn) {
      return this.goDashboard();
    }
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.code) {
        return this.handleLoginGoogle(params.code);
      }
    });
  }

  private async loginSuccess() {
    await this.appComponent.loginSucceeded();
    if (!verifyPasswordExpired(this.authUser, this.router)) return;
    verifyAfterLogin(this.authUser, this.router);
  }

  onEmailKeyUp(event: KeyboardEvent) {
    if (!InputHelper.isEnter(event)) {
      return;
    }
    this.inputPw?.nativeElement.focus();
  }

  onPwKeyUp(event: KeyboardEvent) {
    if (!InputHelper.isEnter(event)) {
      return;
    }
    if (this.canLogin) {
      this.onBtnLogin();
    }
  }

  onBtnForgotPw() {
    DialogService.forgotPw();
  }

  async onBtnLogin() {
    const email = this.getItemValue('email');
    const pw = this.getItemValue('pw');
    this.setProgress(true);
    let [err, result] = await to(this.auth.login(email, pw).toPromise());
    if (err) {
      this.showErr(err);
    } else if (result?.needOtp) {
      this.router.navigate([this.routeVerifyLogin], { queryParams: { loginData: result?.loginData } });
    } else {
      await this.loginSuccess();
    }
    this.setProgress(false);
  }

  private setProgress(value: boolean) {
    this.onProgress = value;
  }

  async onBtnLoginGoogle() {
    this.loginGgAuthUrlFetching = true;
    let [err, result] = await to(this.auth.googleAuthUrl().toPromise());
    if (err) {
      this.showErr(err);
      this.loginGgAuthUrlFetching = false;
    } else {
      const authUrl = result.data.authUrl;
      window.location.href = authUrl;
    }
  }

  private async handleLoginGoogle(code: string) {
    this.loginGgAuthCodeVerifying = true;
    const [err, result] = await to(this.auth.googleAuthVerify(code).toPromise());
    if (err) {
      this.showErr(err);
      this.router.navigate([Const.routeLogin]);
      this.loginGgAuthCodeVerifying = false;
    } else if (result?.needOtp) {
      this.router.navigate([this.routeVerifyLogin], { queryParams: { ggToken: result?.ggToken } });
    } else {
      await this.loginSuccess();
    }
  }

}
