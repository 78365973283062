import { ApiService } from "./api.service";

import { environment } from "@env/environment";

export class RoutingService {
    api: ApiService = null
    baseUrl: string = null

    constructor(api: ApiService) {
        this.api = api
        this.baseUrl = environment.routing2Url
    }

    public getProblem(id) {
        const url = `${this.baseUrl}/problems/${id}`
        return this.api.GET(url)
    }

    public loadProblemLastSolution(id, known=null) {
        const url = `${this.baseUrl}/problems/${id}/last-solution` + (known ? `?last=${known}` : '')
        return this.api.GET(url)
    }

    public getSolution(id) {
        const url = `${this.baseUrl}/solutions/${id}`
        return this.api.GET(url)
    }

    public getSolutionRoutes(id) {
        const url = `${this.baseUrl}/solutions/${id}/routes`
        return this.api.GET(url)
    }

    public getSolutionRoute(id, routeId) {
        const url = `${this.baseUrl}/solutions/${id}/routes/${routeId}`
        return this.api.GET(url)
    }
}