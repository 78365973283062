import './polyfills.ts';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableProdMode} from '@angular/core';
import {environment} from '@env/environment';
import {EnvType} from '@env/type';
import {AppModule} from '@app/app.module';
import { disableLog } from '@services/log';

if (disableLog) {
  // disable console.log in non-development environment
  window.console.log = () => {}
  window.console.info = () => {}
  window.console.warn = () => {}
  window.console.dir = () => {}
}

if (environment.type == EnvType.prod || environment.type == EnvType.stg) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
