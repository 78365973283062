import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PublicComponent } from './comp';
import { PublicRoutingModule } from './routing';
import { LoginV1Module } from '../login-v1/module';
import { VerifyLoginModule } from '../verify-login/module';
import { AppDownloadModule } from '../app-download/module';
import { PasswordExpiredModule } from '@app/password-expired/module';
import { ModuleSetupServer } from '@app/setup-server/module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PublicRoutingModule,
    ModuleSetupServer,
    LoginV1Module,
    VerifyLoginModule,
    AppDownloadModule,
    PasswordExpiredModule
  ],
  declarations: [
    PublicComponent,
  ],
  exports: [
  ],
  providers: [
  ]
})
export class PublicModule {}
