

export class ListShipmentExpandUtil {
  static processListData(listData: Array<{key: string, children: any[], [k: string]: any}>) {
    let mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};
    // Từ listData bao gồm các item (có thể có item.children[]) => tạo dữ liệu để dùng cho nz-table có cấu trúc tree
    listData.forEach(item => {
      mapOfExpandedData[item.key] = this.convertTreeToList(item);
    });
    return mapOfExpandedData;
  }

  static collapse(array: TreeNodeInterface[], data: TreeNodeInterface, isExpanded: boolean): void {
    if (!isExpanded) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.key === d.key)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }

  static toggleChildren(originalItem: TreeNodeInterface, mappedItem: TreeNodeInterface, mapOfExpandedData: {[key: string]: TreeNodeInterface[]}) {
    mappedItem.expand = !mappedItem.expand
    this.collapse(mapOfExpandedData[originalItem.key], mappedItem, mappedItem.expand);
  }

  private static convertTreeToList(root: TreeNodeInterface): TreeNodeInterface[] {
    const stack: TreeNodeInterface[] = [];
    const array: TreeNodeInterface[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: true });
    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level! + 1, expand: false, parent: node });
        }
      }
    }
    return array;
  }

  private static visitNode(node: TreeNodeInterface, hashMap: { [key: string]: boolean }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }
}