<div class="title">Please setup server URLs</div>
<form [formGroup]="formInput" nz-form>
  <ng-container *ngFor="let key of formInputKeys">
    <div class="form-label-v1">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
    <nz-form-item class="form-value-v1">
      <input nz-input 
        [formControlName]="key"
        [placeholder]="getPlaceHolder(key)" autofocus>
    </nz-form-item>
  </ng-container>
</form>

<div class="buttons">
  <button class="btn-submit" nz-button nzType="primary" (click)="onBtnSubmit()" [disabled]="!needUpdate">
    <i nz-icon nzType="save" nzTheme="outline"></i>
    Save
  </button>
  
  <button *ngIf="!isCreateNew" class="btn-cancel" nz-button (click)="onBtnCancel()">
    <i nz-icon nzType="close" nzTheme="outline"></i>
    Cancel
  </button>
</div>
