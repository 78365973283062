export enum EnvType {
  portable = 'portable',
  dev1 = 'dev1',
  dev2 = 'dev2',
  dev3 = 'dev3',
  dev4 = 'dev4',
  dev5 = 'dev5',
  dev = 'development',
  stg = 'staging',
  prod = 'production'
}