import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VerifyLoadTender } from './loadtender/comp';
import { ViewLoadTender } from './view-loadtender/comp';

const routes: Routes = [
  {
    path: 'loadtender', component: VerifyLoadTender,
  },
  {
    path: 'loadtender/:id', component: ViewLoadTender,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VerifyDocumentRoutingModule { }
