import { Component, ElementRef, ViewChild } from '@angular/core';
import { mixinHTMLElementWidthChangedNotifier } from '@abstract/HTMLElementWidthChangedNotifier';
import { BaseComponent } from '@abstract/BaseComponent';
import { NavigationEnd } from '@angular/router';

@Component({
  selector: '[public]',
  templateUrl: './comp.html',
  styleUrls: ['../app.scss']
})
export class PublicComponent extends mixinHTMLElementWidthChangedNotifier(BaseComponent) {
  public get isTopMenuMobile(): boolean {return window.document.body.clientWidth <= 736}

  @ViewChild('content', {static: false}) content: ElementRef<HTMLDivElement>;

  protected get contentElement(): HTMLElement { return this.content.nativeElement }

  constructor(private hostElement: ElementRef<HTMLElement>) {
    super();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        this.topNav?.onNavigationEnd(event.url);
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.handleInitRoute();
  }

  private handleInitRoute() {
    const url = this.router.url.split('?')[0];
    if (url === '/verify') {
      return;
    }
    for (let route of this.router.config) {
      if (route.component?.name == PublicComponent.name) {
        for (let child of route?.children || []) {
          if (url.indexOf(`/${child.path}`) == 0) {
            // Với những sub-route bên trong PublicComponent mà được khai báo tường minh thì sẽ xử lý tiếp ở component
            return;
          }
        }
      }
    }
    if (this.authUser) {
      this.goDashboard();
    } else {
      this.goLogin();
    }
  }

  protected notifyContentWidthChanged(value: number) {
    super.notifyContentWidthChanged(value);
    if (this.isTopMenuMobile) {
      this.hostElement.nativeElement.classList.add('top-menu-mobile');
    } else {
      this.hostElement.nativeElement.classList.remove('top-menu-mobile');
    }
  }

  onRouterActivate(compRef) {
    compRef.parentComponent = this;
  }
}
