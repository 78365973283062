import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerifyDocumentRoutingModule } from './routing';
import { NzInputModule } from 'ng-zorro-antd/input';
import { SharedModule } from '@app/admin/shared/shared.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { VerifyLoadTender } from './loadtender/comp';
import { ViewLoadTender } from './view-loadtender/comp';
import { PdfViewerModule } from '@libs/pdf-viewer/pdf-viewer.module';
import { LoadingModule } from '@app/admin/components/loading-bar/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NzIconModule,
    VerifyDocumentRoutingModule,
    PdfViewerModule,
    LoadingModule,
  ],
  declarations: [
    VerifyLoadTender,
    ViewLoadTender,
  ],
  exports: [
  ],
  providers: [
  ]
})
export class VerifyDocumentsModule {}