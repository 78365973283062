import { ApiService } from "./api.service";

import { environment } from "@env/environment";

export class VehicleTypeService {
    api: ApiService = null
    baseUrl: string = null

    
    constructor(api: ApiService) {
        this.api = api
        this.baseUrl = environment.backendUrl + '/v2/vehicle-types'
    }

    public listAll = (success, error) => {
        // TODO Caching
        return this.api.GET(this.baseUrl).subscribe(
            resp => {
                if (resp)
                    success && success(resp)
                else
                    error && error({'message': 'Not found'})
            }, err => {
                error && error(err)
            }
        )
    }
}