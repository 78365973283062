import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Const } from '@const/Const';
import { BaseDetail } from '@app/admin/base/detail';
import { BaseComponent } from '@abstract/BaseComponent';
import { DateUtil } from '@services/date-utils';
import moment from 'moment';
import { AWSUtil } from '@services/aws-util';
import { AttachedFileUtil } from '@services/attached-file-util';

@Component({
  selector: 'view-loadtender',
  templateUrl: './comp.html',
  styleUrls: ['./style.scss']
})
export class ViewLoadTender extends BaseComponent {
  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }
  fileUrl = '';
  isLoading = true;

  ngOnInit(): void {
    super.ngOnInit();
    this.initData();
  }
  async initData() {
    const id = this.activatedRoute.snapshot.params.id;
    const sign = this.activatedRoute.snapshot.queryParams.sign;
    const url = Const.API_PUBLIC(`verify-documents/${id}?sign=${sign}`);
    this.api
      .GET(url)
      .subscribe(
        (response) => {
          this.fileUrl = AttachedFileUtil.attachedFileUrl(response.data, false);
        },
        (error) => {
          this.isLoading = false;
          this.showErr(error);
        }
      );
  }
  onLoaded(event) {
    this.isLoading = false;
  }
}