import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Const } from '@const/Const';
import { BaseDetail } from '@app/admin/base/detail';
import { BaseComponent } from '@abstract/BaseComponent';
import { DateUtil } from '@services/date-utils';
import moment from 'moment';

@Component({
  selector: 'verify-loadtender',
  templateUrl: './comp.html',
  styleUrls: ['./style.scss']
})
export class VerifyLoadTender extends BaseComponent {
  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }
  isLoading = false;
  searchInput = '';
  date;

  ngOnInit(): void {
    super.ngOnInit();
  }

  onSearchPress(e) {
    //check if last character is press enter
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }
  onSearch() {
    const searchValue = this.searchInput?.trim();
    const date = moment(this.date).format('YYYY-MM-DD')
    if (!searchValue || !date) {
      return;
    }
    this.api
      .POST(Const.API_PUBLIC('verify-documents/loadtender'), { code: searchValue, date: date })
      .subscribe(
        (response) => {
          this.isLoading = false;
          if (response.data) {
            this.router.navigate(['/loadtender', response.data.id], {
              queryParams: {
                sign: response?.data?.sign
              }
            });
          } else {
            this.showErr('Invalid data.')
          }
        },
        (error) => {
          this.isLoading = false;
          this.showErr(error);
        }
      );
  }
}