// Internal App Message Service: Sử dụng để bắn event từ 1 component đến 1-n component khác
// trong component lắng nghe message cần tạo 1 Subscription để subscribe/unsubscribe message service
// private messageSubscription: Subscription;
// constructor(private messageService: InternalMessageService) {
//     this.messageSubscription = this.messageService.getMessage().subscribe((message) => {
//       do something
//     });
//   }
// ngOnDestroy() {
//     this.messageSubscription.unsubscribe();
//   }

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class InternalMessageService {
  private messageSubject = new Subject<any>();

  sendMessage(message: any) {
    this.messageSubject.next(message);
  }

  getMessage() {
    return this.messageSubject.asObservable();
  }
}