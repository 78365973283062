import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService, OnClickCallback } from 'ng-zorro-antd/modal';
import { getInjector } from './injector';
import { Utils } from '../services/utils';

export class UIHelper {
  private static _notification: NzNotificationService;

  static get notification(): NzNotificationService {
    if (!this._notification) {
      let injector = getInjector();
      this._notification = injector.get(NzNotificationService);
    }
    return this._notification;
  }

  static showErr(err): void {
    let msg = Utils.getErrorString(err);
    this.notification.create('error', 'Error', msg);
  }

  static showSuccess(resp): void {
    let msg = Utils.getSuccessString(resp);
    this.notification.create('success', 'Success', msg);
  }

  static showInfo(message: any): void {
    let msg = Utils.getErrorString(message);
    this.notification.create('info', 'Notification', msg);
  }

  static showWarning(title: any = null, message: any): void {
    let msg = Utils.getErrorString(message);
    this.notification.create('warning', title ?? 'Notification', msg);
  }

  static confirmYesNo(message: string, onOk: OnClickCallback<void>) {
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    modalService.confirm({
      nzTitle: message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'Yes',
      nzOnOk: onOk,
      nzCancelText: 'No',
    });
  }
}