import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicComponent } from './comp';
import { LoginV1 } from '../login-v1/comp';
import { VerifyLogin } from '../verify-login';
import { AppDownload } from '../app-download/comp';
import { PasswordExpired } from '@app/password-expired';
import { VerifyDocumentsModule } from '@app/verify-documents/module';
import { SetupServer } from '@app/setup-server';

const routes: Routes = [
  {
    path: '', component: PublicComponent,
    children: [
      { path: 'setup-server', component: SetupServer },
      { path: 'login', component: LoginV1, data: {title: 'Login - WARP Admin'} },
      { path: 'verify', component: VerifyLogin, data: {title: 'Verify Login - WARP Admin'} },
      { path: 'password-expired', component: PasswordExpired, data: {title: 'Password Expired - WARP Admin'} },
      { path: 'app-download', component: AppDownload },       // deprecated
      { path: 'crossdock', component: AppDownload },          // giống với app-download dùng cho external domain. VD https://native-app.wearewarp.com/crossdock
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [
    RouterModule,
    VerifyDocumentsModule //sử dụng để verify documents
  ]
})
export class PublicRoutingModule {}
